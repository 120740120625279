<template>
  <div>
    <mu-container class="content order">
      <mu-appbar color="#07c160" title="预约记录">
        <!-- <mu-button slot="right" icon @click="orderDialog = false">
            <mu-icon value="close"></mu-icon>
        </mu-button> -->
      </mu-appbar>
      <mu-card
        style="width: 100%; margin: 20px auto"
        v-for="item in orders"
        :key="item.id"
      >
        <mu-tabs color="grey300" indicator-color="indigo500">
          <mu-tab class="title" v-if="item.doctor">{{ item.doctor.name}}</mu-tab>
          <span class="status" v-if="item.status==1"><mu-button flat color="#07c160">已预约</mu-button></span>
          <span class="status" v-if="item.status==0"><mu-button flat  color="error">已取消</mu-button></span>
        </mu-tabs>
        <mu-list class="order_list">
          <mu-list-item button  v-if="item.hospital">
            <mu-list-item-content id="order_item">
              <mu-list-item-sub-title>
                <span>预约医院：</span>
              </mu-list-item-sub-title>
            </mu-list-item-content>
            <mu-list-item-action
              ><span class="order_content">{{
                item.hospital.title
              }}</span></mu-list-item-action
            >
          </mu-list-item>
          <mu-list-item button  v-if="item.office" >
            <mu-list-item-content>
              <mu-list-item-sub-title>
                <span>预约科室：</span>
              </mu-list-item-sub-title>
            </mu-list-item-content>
            <mu-list-item-action
              ><span class="order_content">{{
                item.office.title
              }}</span></mu-list-item-action
            >
          </mu-list-item>
          <mu-list-item button v-if="item.hospital">
            <mu-list-item-content>
              <mu-list-item-sub-title>
                <span>医院地址：</span>
              </mu-list-item-sub-title>
            </mu-list-item-content>
            <mu-list-item-action
              ><span  class="order_content">{{
                item.hospital.address
              }}</span></mu-list-item-action
            >
          </mu-list-item>
          <mu-list-item button>
            <mu-list-item-content>
              <mu-list-item-sub-title>
                <span>预约时间：</span>
              </mu-list-item-sub-title>
            </mu-list-item-content>
            <mu-list-item-action
              ><span class="order_content"
                >{{ item.order_date }} {{ item.order_time }}</span
              ></mu-list-item-action
            >
          </mu-list-item>
          <mu-list-item button>
            <mu-list-item-content>
              <mu-list-item-sub-title>
                <span>就诊人：</span>
              </mu-list-item-sub-title>
            </mu-list-item-content>
            <mu-list-item-action
              ><span v-if="item.patient" class="order_content"
                >{{ item.patient.name }}/{{ item.patient.mobile }}</span
              ></mu-list-item-action
            >
          </mu-list-item>

          <!-- <mu-divider></mu-divider>
          <div class="submit">
            <mu-button
              round
              color="indigo500"
              :to="{name: 'Doctor', query: { jgbm: item.hospital.jgbm, rybm: item.doctor.rybm}}"
              >再次预约</mu-button
            >
            
            <mu-button round color="error" v-if="item.status!=0" :disabled="!item.can_cancel" @click="cancelOrder(item.id)">取消预约</mu-button>
            <mu-button round color="error" v-else disabled @click="cancelOrder(item.id)">已取消</mu-button>
          </div> -->
        </mu-list>
      </mu-card>
    </mu-container>
    <WxWorkFooter shift="my"/>
    <mu-dialog
      width="360"
      transition="slide-bottom"
      fullscreen
      :open.sync="addDialog"
    >
      <mu-appbar color="indigo500" title="添加就诊人">
        <mu-button slot="right" icon @click="addDialog = false">
          <mu-icon value="close"></mu-icon>
        </mu-button>
      </mu-appbar>
      <div style="padding: 24px">this is a fullscreen dialog</div>
    </mu-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import WxWorkFooter from "../../components/WxWorkFooter.vue";

export default {
  name: "Home",
  components: {
    WxWorkFooter,
  },
  data() {
    return {
      user: {},
      addDialog: false,
      orders: [],
    };
  },
  created() {
    document.title = "预约记录 - 可恩口腔医院";
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.$http.get("order").then((res) => {
        // if(res.data.code == 204){
        //   this.$router.push({'name':'WxWorkLogin'})
        //   return false;
        // }
        this.orders = res.data.data;
      });
    },
    cancelOrder(id){
      this.$confirm('确认要取消预约 ?', '提示')
      .then((res)=>{
        if(res.result){
          this.$http.post('cancel_order',{id:id})
          .then((res)=>{
            if(res.data.code != 200){
              this.$alert(res.data.msg)
              return false;
            }
            if(res.data.code ==200){
              this.$alert(res.data.msg)
              .then((res)=>{
                this.getOrderList();
              })
            }
          })
          

        }
      })
      

    }
  },
  watch: {},
};
</script>
<style scoped>
.patient {
  padding: 30px 0px;
}
/* .mu-item {
  height: 84px !important;
} */
/* .patient .mu-item-title {
  line-height: 40px;
  height: 40px;
}
.patient .mu-item-sub-title {
  font-size: 14px;
  line-height: 14px;
} */
.order .title {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}
.order .status {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  width: 80px;
  float: right;
  text-align: right;
}
.order_list .mu-item-sub-title {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  font-family: "Microsoft YaHei";
}
#order_item .mu-item {
  height: 36px !important;
}
.order_list .order_content {
  font-size: 16px;
  color: #000;
  display: block;
}
.order_list .submit{
  float:right;
}
.order_list .submit button{
  margin:10px 10px 5px 10px;
}
.mu-button{
  overflow: visible !important;
}
.mu-tabs{
  z-index:10;
}
</style>
